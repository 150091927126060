/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

:root {
    --menu-desktop-height: 68px;
}

.Menu {

    &-MenuWrapper {
        @include desktop {
            min-height: var(--menu-desktop-height);
            margin: calc(var(--header-nav-height) * -1) auto auto;
            z-index: auto;
        }
    }

    &-MainCategories {
        @include desktop {
            padding-left: 170px;
            padding-right: 170px;
        }
    }

    &-ItemList {
        @include desktop {
            width: 100%;
            justify-content: center;
        }

    }

    &-Promotion {
        @include desktop {
            align-items: center;
            justify-content: center;
            color: #5a5a5a;
        }
    }

    &-Social {
        @include desktop {
            display: none;
        }
    }

    &-PageLink {
        @include desktop {
            margin-top: 0!important;
        }
        @include mobile {
            font-size: var(--p-font-size);
        }
    }

    &-SubItemWrapper {
        @include desktop {
            text-align: center;
            margin-left: 1rem;
            margin-right: 1rem;
        }
        & > .Menu-Link {
            @include desktop {
                color: #5a5a5a;
            }

        }
    }

    &-Overlay {
        @include desktop {
            top: 0;
        }
    }

    &-CompareLinkWrapper {
        @include mobile {
            display: none;
        }
    }

    &-ItemCaption{
        @include mobile {
            font-size: 1.6rem;
            font-weight: 600;
        }
    }

    &-SubCategoriesWrapperInner {
        @include desktop {
            background-color: transparent;
            
        }
    }

    &-SubCategories {
        @include desktop {
            max-width: 700px;
            background-color:#fff;
            margin-left: calc(50%);
            transform: translateX(-50%);
            
            padding: 2rem 150px 2rem 150px;
            text-align: center !important;
        }
    }
}