.cms-featured-cats-block {
    h2 {
        display: block;
        width: 100%;
        text-align: center;
        margin-bottom: var(--cms-spacing-x3)
    }

    &__cat {
        display: block;

        &:before {
            content: "";
            position: absolute;
            left: 0; right: 0;
            top: 0; bottom: 0;
            z-index: 10;
            background: rgb(0,0,0);
            background: linear-gradient(180deg, rgba(0,0,0,0.020045518207282953) 0%, rgba(0,0,0,0.3981967787114846) 35%, rgba(0,0,0,0.4990371148459384) 100%);

            
  
        }

        &--gradient-top-down {
            &:before {
                background: linear-gradient(0deg, rgba(0,0,0,0.020045518207282953) 0%, rgba(0,0,0,0.3981967787114846) 35%, rgba(0,0,0,0.4990371148459384) 100%);
            }
        }

        &--just-overlay {
            &:before {
                background: rgba(0, 0, 0, 0.2);
            }
        }

        &__introtitle {
            text-transform: uppercase;
            letter-spacing: 0.3em;
            font-size: 1rem;
            line-height: 1.2;
            font-weight: lighter;

            @include desktop {
                font-size: 1.2rem;
            }

            &__left {
                text-transform: uppercase;
                letter-spacing: 0.3em;
                font-size: 1rem;
                line-height: 1.2;
                font-weight: lighter;

                @include desktop {
                    font-size: 1.2rem;
                }
            }
        }

        &__title {
            display: block;
            text-transform: uppercase;
            padding: 0 3rem;
            font-size: var(--h4-font-size);
            margin: 0 auto 1rem; 
            text-decoration: none;          
           
            @include desktop {
                &::after {
                    content: '';
                    position: absolute;
                    bottom: -2px;
                    left: 0;
                    width: 0%;
                    border-bottom: 2px solid currentColor;
                    transition: width 0.5s ease;
                }
            }

            @include desktop {
                max-width: 500px;
                font-size: var(--h3-font-size);  
            }

            &__left {
                display: block;
                text-transform: uppercase;
                font-size: var(--h5-font-size);
                margin-bottom: 1rem;

                @include desktop {
                    font-size: var(--h4-font-size);
                }
            }
        }

        &__text {
            display: block;
            padding: 0 3rem;
            font-size: 1.6rem;
            margin: 0 auto 2rem;

            @include desktop {
                max-width: 600px;
                font-size: 1.8rem;
            }

            &__left {
                display: block;
                font-size: 1.2rem;
                font-weight: lighter;

                @include desktop {
                    font-size: 1.8rem;
                }
            }
        }
    }

    &__cat-content {
        display: block;
        min-height: 400px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        
        @include desktop {
            min-height: 600px;
        } 

        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border: 7px solid transparent;
            box-shadow: inset 0 0 0 2px white;
            z-index: 12;

            @include desktop {
                border-width: 10px;
            }
        }
    }

    &__cat-container {
        position: absolute;
        top: 10%;
        display: block;
        width: 100%;
        font-size: var(--h4-font-size);
        color: #fff;
        text-align: center;
        z-index: 20;
        font-weight: 700;
        padding: .5rem 0;

        &:hover {
            .cms-featured-cats-block__cat__title {
                &:after {
                    width: 100%;
                }
            }
        }

        &--center {
            top: 10%;
        }

        &--center {
            top: 50%;
            transform: translateY(-50%);
        }

        &--bottom {
            top: unset;
            bottom: 10%;
        }

        &--left {
            top: 10%;
            left: calc(50% - 150px);
            max-width: 300px;
            background-color: #fff;
            color: #000;
            padding: 4rem 4rem;
        
            @include desktop {
                max-width: 400px;
                top: 10%;
                left: 5%;
                text-align: left;
            }

            &::after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                border: 7px solid transparent;
                box-shadow: inset 0 0 0 2px black;
                z-index: 2;
    
                @include desktop {
                    border-width: 10px;
                }
            }

            .cms-button {
                margin-top: 2rem;
                width: 100%;
                text-align: center;
                color: #fff !important;
                background-color: #000; 
            }
        }
        
        .cms-button {
            color: var(--white);
            border-color: var(--white);
            border-width: 2px;
            font-weight: bold;

            &:hover {
                background-color: rgba(1,1,1,0.7);
                border-color: rgba(255,255,255,0.7);

            }
        }
    }
}