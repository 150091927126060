/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.Footer {
    border-top: 1px solid var(--primary-divider-color);
    padding: 5rem 0;
    text-align: center;

    p {
        font-size: 1.2rem;
        padding-top: 1rem;
    }

    &-copyright {

        &-emaerket {
            display: inline-block;
            max-width: 100px;
            -webkit-filter: grayscale(100%);
            vertical-align: top;

            &:hover {
                -webkit-filter: none;
            }
        }

    }

    .Image {
        display: inline-block;
        height: auto;
        margin: 0 auto;
        padding: 0;
        width: auto !important;
        
        .Image-Image {
            height: 30px !important;
            padding: 0 0.5rem;
            position: relative;
            -webkit-filter: grayscale(100%);
            width: auto !important;
            
            &:hover {
                -webkit-filter: none;
            }
        }
    }


}


