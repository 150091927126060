.header-usp {
    &__list {
        display: flex;
        justify-content: center;
        li {
            padding-left: 0;
            margin-bottom: 0;
            margin-right: 2rem;
            font-size: 1.2rem;
            line-height: 3rem;
            text-transform: uppercase;

            @media only screen and (max-width: 1200px) {
                font-size: 1rem;
            }
            
            &:last-child {
                margin-right: 0;
            }
            &:before {
                display: none;
            }

            .Image {
                padding: 0;
                height: auto;
                display: block;
        
                .Image-Image {
                    position: relative;
                    height: auto !important;
                    object-fit: unset;
                    display: block;
                }
        
                &:not(.width-control) {
                    width: 100% !important;
                }
            }
        }

        li.trustpilot {
            max-width: 75px;
            padding-top: 5px;
        }
        li.emaerket {
            max-width: 100px;
        }
    }
}
