.cms-large-hero {
    height: 400px;
    
    @include desktop {
        height: 400px;
    }   

    &__container {
        height: 100%;  
    }

    &__image {
        height: 100%;
        width: 100%;
        position: absoulte;
        z-index: 1;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top;
    }

    &__overlay {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 3;
        background-color: rgba(0, 0, 0, .2);

        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border: 7px solid transparent;
            box-shadow: inset 0 0 0 2px white;
            z-index: 4;

            @include desktop {
                border-width: 10px;
            }
        }
    }

    &__text {
        position: absolute;
        left: calc(50% - 145px);
        text-align: center;
        z-index: 5;
        width: 290px;
        max-width: 80%;
        background-color: #fff;
        color: #000;
        padding: 5rem 2rem 4rem;
        text-align: center;
        top: 35%;
        transform: translateY(-50%);

        @include desktop {
            width: 450px;;
            left: calc(50% - 225px);

            &__swedish {
                width: 500px;;
                left: calc(50% - 250px);
            }
        }
    
        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border: 7px solid transparent;
            box-shadow: inset 0 0 0 2px black;
            z-index: 2;

            @include desktop {
                border-width: 10px;
            }
        }
      

        p {
            position: relative;
            font-size: 1.2rem;
            color: black;
            line-height: normal;
            padding: 0 2rem;

            @include desktop {
                font-size: 1.8rem;
            }

            &.introtitle {
                text-transform: uppercase;
                letter-spacing: 0.3em;
                font-size: 1rem;
                line-height: 1.2;
                font-weight: lighter;

                @include desktop {
                    font-size: 1.2rem;
                }
            }

        }

        h1 {
            position: relative;
            font-size: var(--h5-font-size) !important;
            color: black;
            line-height: 1em;
            padding: 0 2rem;
            text-transform: uppercase;
            margin-top: 0;
            

            @include desktop {
                font-size: var(--h3-font-size) !important;
                margin-top: 0;

            }

        }

    }

    &__trustWidget {
        position: absolute;
        margin: 0 auto;
        width: 100%;
        z-index: 5;
        top: 75%;
        transform: translateY(-50%);

        @include desktop {
            top: 79%;
        }

        &__emaerket {
            width: 290px;
            max-width: 80%;
            margin: 0 auto 1rem;

            @include desktop {
                width: 800px;
            }
        }
    }

}