/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

#posestorrelse {
    border-radius: 7px;
}

.ProductActions {
    @include desktop {
        .FieldSelect-Options::-webkit-scrollbar {
            display: block;
            width: 1em;
        }

        .FieldSelect-Options::-webkit-scrollbar-track {
            // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #f1f1f1;
        }

        .FieldSelect-Options::-webkit-scrollbar-thumb {
            display: block;
            background-color: #c1c1c1;
            outline: 1px solid #e0e0e0;
        }
    }
}
