.CmsPage {
    
    &-Heading {
        
        .HomePage & {
            display: none;
        }

        @include desktop {
            text-align: center;
        }
    }

    .CmsPage-Content {
        h1 {
            font-size: var(--h2-font-size);
        }
        
        h2 {
            font-size: var(--h4-font-size);
        }
        
        h3 {
            font-size: var(--h5-font-size);
        }
        
        h4 {
            font-size: var(--h6-font-size);
        }
        

        @include desktop {
            h1 {
                font-size: var(--h1-font-size);
            }
            
            h2 {
                font-size: var(--h2-font-size);
            }
            
            h3 {
                font-size: var(--h3-font-size);
            }
            
            h4 {
                font-size: var(--h4-font-size);
            }
            
            h5 {
                font-size: var(--h5-font-size);
            }
            
            h6 {
                font-size: var(--h6-font-size);
            }
        }
    }
}


.cms-section {

    > .cms-section-container {

        &-center {
            @include desktop {
                text-align: center;
                max-width: 900px;
                margin: 0 auto;

                li::before, ul > li::before {
                    content: unset;
                }
            }
        }
    }
}