/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.StoreItems {
    @include desktop {
        &-Item {
            font-size: unset;
            padding: unset;
            margin-left: 10px;
            white-space: unset;
            width: 25px;
            height: 25px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: top;

            &:hover {
                background: unset;
            }
        
            &_label_Danmark {
                background-image: url(icons/Danmark.png);
                
                &:hover {
                    background-image: url(icons/Danmark.png);
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: top;
                    width: 28px;
                    height: 28px;
                }
            }

            &_label_Sverige {
                background-image: url(icons/Sverige.png);

                &:hover {
                    background-image: url(icons/Sverige.png);
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: top;
                    margin-left: 7px;
                    width: 28px;
                    height: 28px;
                }
            }
        }
    }
}