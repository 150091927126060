/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';


:root {
    @include desktop {
        --header-menu-height: 60px; 
        --header-top-menu-height: 40px;
        --header-nav-height: 60px;
        --menu-desktop-height: 60px;
        --header-total-height: calc( var(--header-height) + var(--offline-notice-height) + var(--demo-notice-height) + env(safe-area-inset-top) );
    }   
}


.Header {
    @include desktop {
        --header-logo-height: 30px !important;
        --header-logo-width: 158px !important;
    }

    &_isCheckout { // hide header on checkout
        display: none;
    }

    &-Wrapper {
        @include desktop {
            margin-bottom: calc(var(--header-total-height) + 9px);
        }
        &_isCheckout { // hide header on checkout 
            margin-bottom: 0;
        }
    }

    &-Title {
        margin-top: auto!important;
        @include mobile {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

    }
    &-TopMenu {
        padding: 0 1.2rem;
        max-width: unset;
        background-color: black;
        color: white;
        display: block;
    }

    &-TopMenuWrapper {
        display: flex;
        height: var(--header-top-menu-height);
        align-items: center;
        justify-content: flex-end;
        max-width: var(--content-wrapper-width);
        margin: 0 auto;
        padding-left: 1.2rem;
        padding-right: 1.2rem;
    }

    &-Usp {
        position: absolute;
        left: 0;
        right: 0;
        padding-left: 82px;
        padding-right: 82px;

        @media only screen and (max-width: 1200px) {
            padding-left: 0;
        }
    }

    &-LogoWrapper {
        bottom: auto;
        top: auto;

        @include desktop {
            left: 15px;
            right: auto;
            top: 20px;
        }
    }

}
